<template>
	<div style="width: 100%; min-height: calc(100vh - 120px); display: flex; justify-content: center; align-items: center">
		<!-- <v-dialog
			v-if="dialogShow"
			v-model="dialogShow"
			width="40%"
			persistent
			hide-overlay
		> -->
		<v-card style="max-width: 700px">
			<v-expansion-panels focusable>
				<v-expansion-panel
					v-for="item in optionList"
					:key="item.OptID"
					class="pt-0 pb-0"
				>
					<v-expansion-panel-header
						@click="getQuerys(item)"
						style="min-height: 0px; height: 0px"
					>
						{{ item.OptName }}
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-list dense>
							<v-list-item-group color="primary">
								<v-list-item
									v-for="item2 in querys"
									:key="item2.QryID"
									@click="openModalFilter(item2)"
									style="min-height: 25px"
								>
									<v-icon left>
										mdi-checkbox-marked-circle
									</v-icon>
									{{ item2.QryNameReport }}
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
		<!-- </v-dialog> -->

		<v-dialog v-if="dialogFilter" v-model="dialogFilter" max-width="350" persistent>
			<v-card>
				<s-toolbar label="Query Show" dark color="#8e8f91" close @close="closeModalFilter()"></s-toolbar>
				<v-container>
					
					<v-row style="margin-top: 5px">
						<v-col
							cols="12"
							style="margin-top: -10px"
							v-for="(item, index) in itemsParameter"
							:key="item.CfdID"
						>
							<s-date
								:label="item.CfdParameterName"
								v-if="item.TypeParameterAbbreviation == 's-date'"
								v-model="parametergen[index]"
							></s-date>

							<s-text
								:label="item.CfdParameterName"
								v-if="item.TypeParameterAbbreviation == 's-text'"
								v-model="parametergen[index]"
							></s-text>

							<s-select-definition
								:def="item.CfdDefID"
								:label="item.CfdParameterName"
								v-if="item.TypeParameterAbbreviation =='s-select-definition'"
								v-model="parametergen[index]"
							></s-select-definition>
							
							<s-select
								v-if="item.TypeParameterAbbreviation =='component'"
								:label="item.CfdParameterName"
								:item-text="item.CfdItemText"	
								:item-value="item.CfdItemValue"
								full
								autocomplete
								:items="item.itemsAll"
								v-model="parametergen[index]"
							>
							<!-- :items="item.itemsAll" -->
							<!-- v-model="parametergen[index]" -->
							</s-select>
							<div style="display: flex; align-items: center; gap: 5px;" v-if="item.TypeParameterAbbreviation =='s-multiple'">
								<!-- <v-select
									v-model="parametergen[index]"
									clearable
									:label="item.CfdParameterName"
									:items="JSON.parse(item.CfdQuerySql)"
									multiple
									return-object
									item-text="text"
									item-value="value"
									>
									<template v-slot:selection="{ item, index, data = parametergen }">
										<span v-if="index === 0">{{ item.text }}&nbsp;</span>
										<span
										v-if="index === 1"
										class="grey--text text-caption"
										>
										(+{{ data.length - 1 }} otros)
										</span>
									</template>
								</v-select> -->
								<div style="display: flex; flex-direction: column; width: 100%;">
									<span style="font-size: 10.625px; font-weight: bolder; color: rgba(0, 0, 0, 0.6);">{{ item.CfdParameterName }}</span>
									<v-autocomplete
										v-model="parametergen[index]"
										:items="JSON.parse(item.CfdQuerySql)"
										hide-details
										dense
										multiple
										clearable
										solo
										class="autocompleteQuery"
									></v-autocomplete>
								</div>
								<button style="background-color: #4ECA8A; padding: 5px 10px; border-radius: 5px; transform: translateY(2.5px);" @click="openDialogEdit(item)">
									<v-icon color="white">mdi-plus-circle-outline</v-icon>
								</button>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" style="display: flex; gap: 5px;">
							<v-btn
								x-small
								elevation="0"
								color="info"
								@click="runView()"
								style="text-transform: none;"
								>Visualizar</v-btn
							>
							<v-btn
								x-small
								elevation="0"
								color="success"
								@click="downloadReport()"
								style="text-transform: none;"
								>Descargar</v-btn
							>
							<v-btn
								x-small
								elevation="0"
								@click="closeModalFilter()"
								style="text-transform: none;"
								>Cancelar</v-btn
							>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
			v-if="dialogReport"
			v-model="dialogReport"
			width="90%"
			height="80%"
			transition="dialog-bottom-transition"
		>
			<!-- <v-card> -->
			<s-toolbar
				:label="report.QryNameReport"
				color="#BAB6B5"
				dark
				close
				@close="closeModalReport()"
			>
			</s-toolbar>
			<!-- </v-card> -->

			<v-card outlined>
				<v-row style="margin: auto; margin-top: -20px">
					<v-col cols="12"
						><v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Buscar"
							single-line
							hide-details
						></v-text-field
					></v-col>
					<v-col cols="12" style="margin-top: -20px">
						
							<v-data-table
								item-key="Line"
								:items="items"
								:headers="headers"
								:search="search"
								class="elevation-1 table"
								dense
								v-model="selectedRow"
								@click:row="rowClick"

							>
							</v-data-table>
						
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="processing"
			:persistent="messageProcessing.length == 0"
			width="400"
		>
			<v-card color="primary" dark>
				<v-card-text v-if="messageProcessing.length == 0">
					Por favor espere
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
				<v-card-text v-else>
					<div class="text--white pt-4">
						Error al realizar busqueda <br />
						{{ messageProcessing }}
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="openDialog" v-if="openDialog" max-width="500" persistent>
			<v-card>
				<s-toolbar label="Item a Editar" dark color="#8e8f91" close @close="openDialog = false" save @save="saveItem()">
					<v-tooltip bottom="">
						<template v-slot:activator="{ on }">
							<v-btn text v-on="on" small elevation="0" @click="dialogMasive = true">
								<v-icon>mdi-plus-circle-multiple-outline</v-icon>
							</v-btn>
						</template>
						<span>Agregar asignación</span>
					</v-tooltip>
				</s-toolbar>
				<v-container>
					<v-row>
						<v-col cols="5">
							<s-text
								v-model="selectEdit.CfdItemValue"
								label="Item-Value"
							></s-text>
						</v-col>
						<v-col cols="5">
							<s-text
								v-model="selectEdit.CfdItemText"
								label="Item-Text"
							></s-text>
						</v-col>
						<v-col cols="2" align-self="end">
							<button style="background-color: #4ECA8A; padding: 5px 10px; border-radius: 5px;" @click="addParameter()">
								<v-icon color="white">mdi-plus-circle-outline</v-icon>
							</button>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-data-table 
								:headers="headers" 
								:items="jsonSelectMultiple"
								dense
								:itemsPerPage="[5]"
							>
							<template v-slot:item.actions="{ item }">
								<v-btn color="red" @click="deleteItem(item.value)" icon>
								<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogMasive" v-if="dialogMasive" max-width="500" persistent>
			<v-card>
				<s-toolbar label="Registro Masivo" dark color="#8e8f91" close @close="dialogMasive = false"></s-toolbar>
				<v-container>
					<v-row>
						<v-col >
              				<s-import-excel
								:headers = "headersMasive"
								@loaded	="saveMassive($event)"
              				></s-import-excel>
			  			</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sOption from "@/services/Security/OptionService.js";
	import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
	import _sQryConfigurationDetailService from "@/services/QueryManager/QryConfigurationDetailService";
	import _sAuth from "@/services/AuthService";


	export default {
		components: { },
		data() {
			return {
				optionList: [],
				show: false,
				querys: [],
				dialogReport: false,
				dialogFilter: false,
				dialogShow: true,
				report: {},

				items: [],
				headers: [],
				search: "",
				processing: false,
				messageProcessing: "",
				itemsParameter: [],
				parametergen: [],
				usrID: this.$fun.getUserID(),
				selectedRow: [],
				paramsTMP: [],
				countComponent : 0,
				itemsDataComponent: [],
				openDialog: false,
				selectEdit: {},
				jsonSelectMultiple: [],
				headers : [
					{ text: "Valor", value: "value" },
					{ text: "Texto", value: "text" },
					{ text: "Acciones", value: "actions", sortable: false, width: 70 },
				],
				itemTem: {},
				dialogMasive: false,
				headersMasive: [
                    {
						column	: 	"VALOR",
						value	: 	"VALOR",
						text 	: 	"VALOR"
					},
					{
						column	: 	"TEXTO",
						value	: 	"TEXTO",
						text 	: 	"TEXTO"
					}
				],
			};
		},

		methods: {

			initialize() {
				_sAuth.initialize(this.usrID).then((response) => {
        			this.optionList = response.data.UserMenu;
				});
			},

			rowClick: function(item, row) {
				if (item !== undefined) {
					this.selectedRow = [item];
				}
			},

			getQuerys(item) {
				item.UsrID = this.$fun.getUserID();
				_sQryConfigurationService
					.list(item, this.$fun.getUserID())
					.then((resp) => {
						if (resp.status == 200) {
							this.querys = resp.data;
							
						}
					});
			},

			openModalFilter(item) {
				// console.log('OPEMMMMMMM', item);
				this.itemTem = {...item};
				this.report = {};
				this.report = item;
				this.countComponent = 0;
				_sQryConfigurationDetailService
					.list(this.report, this.$fun.getUserID())
					.then((r) => {
						if (r.status == 200) {
							// console.log(r.data);
							this.paramsTMP = r.data
							
							if(this.paramsTMP.length > 0) {

								this.paramsTMP.forEach((element, index) => {
									
									if(element.CfdQuerySql !== null && element.TypeParameterAbbreviation =='component'){
										this.countComponent ++;
									}
									
								});

								this.paramsTMP.forEach((element, index) => {
									
									if(element.CfdQuerySql !== null && element.TypeParameterAbbreviation =='component'){
										this.hasChangeComponent = true;
										_sQryConfigurationService
											.execuetSQL({TransactSQL: element.CfdQuerySql}, this.$fun.getUserID())
											.then(resp => { 
											
												element.itemsAll = resp.data
												this.itemsDataComponent[index] = resp.data
											})
									}
									
								});
							}
							
							this.itemsParameter = this.paramsTMP;

							setTimeout(() => {
								if(this.dialogFilter == false)
								{
									
									this.dialogFilter = true;
								}
							}, this.countComponent * 100);

							/* if (this.hasChangeComponent == false)
							{
								
							} */
							
						}
					});
							
			},


			closeModalFilter() {
				this.itemsParameter = []
				this.dialogFilter = false;
			},

			closeModalReport() {
				this.items = [];
				this.headers = [];
				this.dialogReport = false;
			},

			order() {
				let i = 0;
				this.items.map(e => {
					i++;
					e.Line = i;
				});
			},


			runView() {
				this.dialogReport = true;
				const data = this.parametergen
					.map(item => 
						Array.isArray(item) 
						? `"${item.map(obj => `'${obj}'`).join(', ')}"`
						: item
					)
					.join(', ');
				this.report.QryNameProcedure = "";
				this.report.QryParamsArrayValue = data; //this.DateBegin + "," + this.DateEnd + "," + this.number;

				this.report.QryNameProcedure =
					this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;

				// console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", this.report);

				this.processing = true;
				_sQryConfigurationService
					.querygeneral(this.report, this.$fun.getUserID())
					.then(
						(resp) => {
							if (resp.status == 200) {
								if(resp.data.length == 0)
								{
									this.$fun.alert("No se encontraron registros", "warning");
									this.processing = false;
									return;
								}
								this.processing = false;
								this.items = resp.data;
								// console.log(
								// 	"propiedadesssssssssssssssssssssssssssssss",
								// 	Object.keys(this.items[0]).length,
								// 	Object.keys(this.items[0])
								// );

								for (
									var i = 0;
									i < Object.keys(this.items[0]).length;
									i++
								) {
									this.headers.splice(i, i, {
										text: Object.keys(this.items[0])[i],
										value: Object.keys(this.items[0])[i],
										width: "150",
										sortable: false,
									});
								}

								this.order()
							}
						},
						(e) => {
							this.error = true;
							this.messageProcessing = e.response.data.Message;
							this.processing = true;
						}
					);
			},


			downloadReport() {
				const data = this.parametergen
					.map(item => 
						Array.isArray(item) 
						? `"${item.map(obj => `'${obj.value}'`).join(', ')}"`
						: item
					)
					.join(', ');
				this.report.QryNameProcedure = "";
				this.report.QryParamsArrayValue = data; //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryNameProcedure =
				this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;

				this.processing = true;
				_sQryConfigurationService
					.downloadexcel(this.report, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
								this.processing = false;

								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									this.report.QryNameReport
								);
							}
						},
						(e) => {
							this.messageProcessing = e.response.data.Message;
							this.processing = true;
						}
					);

				// console.log("aaaaa ", this.parametergen);
			},
			openDialogEdit(item) {
				// console.log("CfdID:", item);
				this.selectEdit = {...item};
				this.jsonSelectMultiple = JSON.parse(item.CfdQuerySql);
				this.openDialog = true;
			},
			addParameter(){
				if(this.selectEdit.CfdItemValue == "" || this.selectEdit.CfdItemText == ""){
					this.$fun.alert("Ingrese Item-Value y Item-Text", "warning");
					return;
				}
				const data = {}
				data.text = this.selectEdit.CfdItemText;
				data.value = this.selectEdit.CfdItemValue;
				this.jsonSelectMultiple.push(data);
				this.selectEdit.CfdItemValue = "";
				this.selectEdit.CfdItemText = "";
			},
			async saveItem() {
				// console.log("item", item);
				this.selectEdit.CfdQuerySql = JSON.stringify(this.jsonSelectMultiple);
				await _sQryConfigurationDetailService
				.save(this.selectEdit, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Parametro agregado correctamente", "success");
						// this.getDataParameter();
						this.selectEdit = {};
						this.jsonSelectMultiple = [];
						this.openModalFilter(this.itemTem);
					}
				})

				this.openDialog = false;
			},
			deleteItem (item) {
				this.jsonSelectMultiple.splice(this.jsonSelectMultiple.indexOf(item), 1)
			},
			async saveMassive(items) {
				console.log("items", items);
				for (const item of items) {
					const data = {
						value: item.VALOR,
						text: item.TEXTO,
					};

					this.jsonSelectMultiple.push(data);
				}

				this.dialogMasive = false;
				this.$fun.alert("Data Cargada", "success");
			},
		},

		created() {
			this.initialize();
		},
	};
</script>
<style>
.autocompleteQuery .v-input__slot {
  	height: 100% !important;
  	max-height: 80px !important;
	box-shadow: none !important;
	border: 1px solid rgba(0, 0, 0, 0.38) !important;
}
.autocompleteQuery .v-input__slot .v-select__selections {
  	height: 100% !important;
  	max-height: 80px !important;
  	overflow: hidden !important;
  	overflow-y: auto !important;
}
</style>

			<!-- setListeners(div) {
						var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

						console.log("divdivdivdivdivdiv",div);

						div.addEventListener("mousedown", function (e) {
							curCol = e.target.parentElement;
							nxtCol = curCol.nextElementSibling;
							pageX = e.pageX;

							var padding = paddingDiff(curCol);

							curColWidth = curCol.offsetWidth - padding;
							if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
						});

						div.addEventListener("mouseover", function (e) {
							e.target.style.borderRight = "2px solid #0000ff";
						});

						div.addEventListener("mouseout", function (e) {
							e.target.style.borderRight = "";
						});

						document.addEventListener("mousemove", function (e) {
							if (curCol) {
								var diffX = e.pageX - pageX;

								if (nxtCol)
									nxtCol.style.width = nxtColWidth - diffX + "px";

								curCol.style.width = curColWidth + diffX + "px";
							}
						});

						document.addEventListener("mouseup", function (e) {
							curCol = undefined;
							nxtCol = undefined;
							pageX = undefined;
							nxtColWidth = undefined;
							curColWidth = undefined;
						});
			},
			createDiv(height) {
						var div = document.createElement("div");
						div.style.top = 0;
						div.style.right = 0;
						div.style.width = "5px";
						div.style.position = "absolute";
						div.style.cursor = "col-resize";
						div.style.userSelect = "none";
						div.style.height = height + "px";
						return div;
			},

			paddingDiff(col) {
						if (this.getStyleVal(col, "box-sizing") == "border-box") {
							return 0;
						}

						var padLeft = this.getStyleVal(col, "padding-left");
						var padRight = this.getStyleVal(col, "padding-right");
						return parseInt(padLeft) + parseInt(padRight);
			},

			getStyleVal(elm, css) {
						return window
							.getComputedStyle(elm, null)
							.getPropertyValue(css);
			},

			resizableGrid(table){
				
				//console.log("table",table);
				var row = table.getElementsByTagName("tr")[0],
						cols = row ? row.children : undefined;


					console.log("row",row);
					if (!cols) return;

					table.style.overflow = "hidden";

					var tableHeight = table.offsetHeight;

					for (var i = 0; i < cols.length; i++) {
						var div = this.createDiv(tableHeight);
						cols[i].appendChild(div);
						cols[i].style.position = "relative";
						this.setListeners(div);
					}		
			},

			openJS() {
				var tables = document.getElementsByTagName("table"); 
				
				for (var i = 0; i < tables.length; i++) {
					console.log("table",tables[i]);
					this.resizableGrid(tables[i]);
				}
			}, -->
